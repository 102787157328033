.FieldsetLocation__disabled-container {
  position: relative;
  button {
    outline: none;
    background-color: transparent;
    border: none;
    position: absolute;
    right: 0px;
    top: 5px;
    z-index: 9;
    cursor: pointer;
  }
}
