.hcfa-form {
  .ant-steps-item-title {
    line-height: 1.4 !important;
  }
  label {
    font-size: 12px;
    width: 100%;
    text-transform: uppercase;

    display: flex;
    flex-wrap: wrap;
  }
  .flex-1-1-auto {
    flex: 1 1 auto;
  }

  .ant-radio-group {
    display: flex;
    align-items: center;
    // label {
    //   width: auto;
    // }
  }
  .flex {
    display: flex;
    align-items: center;
    width: 100%;
    .ant-picker-small {
      width: 100%;
    }
  }
  .flex-column {
    flex-direction: column;
    align-items: flex-start;
  }
  .ant-form-item {
    font-size: 12px;
    margin-bottom: 0;
    width: 100%;
    padding: 0 5px;
  }

  .row-1 {
    .ant-radio-group {
      label:not(:last-child) {
        margin-right: 10px;
      }
      label {
        width: auto;
        margin-right: 10px;
        display: inline-block;
      }
    }
  }
  .w-100 {
    width: 100%;
  }
  .w65-35 {
    section {
      &:first-child {
        width: 65%;
      }
      &:last-child {
        width: 35%;
      }
    }
  }
  .w35-30-35 {
    section {
      &:first-child {
        width: 35%;
      }
      &:nth-child(2) {
        width: 30%;
      }
      &:last-child {
        width: 35%;
      }
    }
  }
  .w30-30-35 {
    section {
      width: 30%;
      &:last-child {
        width: 35%;
        min-width: 35%;
      }
    }
  }
  .w30-15-20-35 {
    span.w-35 {
      width: 35%;
      min-width: 35%;
    }
  }
  .w50-50 {
    section {
      &:first-child {
        width: 50%;
      }
      &:last-child {
        width: 50%;
      }
    }
  }
  .w35-65 {
    section {
      &:first-child {
        width: 35%;
      }
      &:last-child {
        width: 65%;
      }
    }
  }
  .w45-40-15 {
    section {
      &:first-child {
        width: 45%;
      }
      &:nth-child(2) {
        width: 35%;
      }
      &:last-child {
        width: 20%;
      }
    }
  }
  .w-100 {
    width: 100%;
  }
  .ant-form-item-label {
    margin-bottom: 0px;
    line-height: 1;
    padding: 0;
    overflow: visible;
    margin-top: 0px;
    .medicare {
      span {
        width: 115px;
        display: inline-block;
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    left: -8px;
    z-index: 9;
    top: 5px;
  }
  .ant-radio-wrapper {
    .ant-radio {
      input {
        margin-left: 0;
      }
    }
  }
  .inputs-wrapper {
    align-items: stretch;
    section {
      padding: 5px;
      border: 1px solid #333;
      width: 100%;
    }
  }
  .ant-select-selection-item {
    font-size: 14px;
  }
  .ant-divider {
    margin: 0;
    border-top: 1px solid #333;
    width: calc(100% + 10px);
    margin-left: -5px;
    margin-top: 5px;
  }
  .ant-input-number {
    width: 100%;
  }
  .ant-table-cell {
    text-align: center;
  }
  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td {
    padding: 5px;
    font-size: 12px;
  }
  .row-12 {
    .flex-column {
      justify-content: space-between;
    }
  }
}

.medicare-wrapper {
  .ant-form-item-row {
    //border: 1px solid rgba(51, 51, 51, 0.1);
    padding: 0.5rem;
    border-radius: 5px;
    min-width: 130px;
  }
}

.improved-form-wrapper {
  padding: 2rem;
  padding-right: 0;
  .flex {
    gap: 1rem;
  }
  .content-wrapper {
    display: flex;
    align-items: flex-start;
    .ant-steps-vertical {
      max-width: 250px;
    }
    .steps-content {
      flex: 1 1 auto;

      margin: 0 auto;
      margin-left: 4rem;
      overflow: hidden;

      border: 1px solid #d5d5d5;
      background-color: white;
      padding: 3rem;
      border-radius: 20px;
      h1 {
        font-size: 25px;
        padding: 0em 5px 0.5em 0;
        text-transform: uppercase;
      }
      h2 {
        font-size: 22px;
        font-weight: 600;
        text-align: left;
        margin-bottom: 1rem;
        margin-top: 1rem;
        color: #1890ff;
        // text-decoration: underline;
        text-transform: uppercase;
        opacity: 0.8;
      }
      .ant-form-item-label {
        padding-bottom: 5px;
        margin-top: 0;
      }
      label {
        font-size: 12px;
        height: initial;
        // &.ant-radio-button-wrapper {
        //   &:hover {
        //     background-color: rgba(0, 0, 0, 0.05);
        //   }
        // }
      }
    }
    .ant-form-item {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: 'tnum', 'tnum';
      margin-bottom: 18px;
      vertical-align: top;
      // width: unset;
    }
  }
  .steps-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
    gap: 1rem;
  }
  .program-radio-btns {
    .ant-radio-group {
      justify-content: space-between;
      gap: 20px;
      label {
        width: 100%;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        padding: 10px 20px;
        height: unset;
        line-height: 1.4;
        text-align: center;
        height: 75px;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        &::before {
          display: none;
        }
      }
    }
  }
  .sex-and-relationship {
    // .ant-form-item {
    //   &:first-child {
    //     width: auto;
    //     min-width: 160px;
    //   }
    // }
    .ant-radio-group {
      label {
        width: 100%;
      }
    }
  }
  .ant-radio-group {
    label {
      justify-content: center;
    }
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    top: 0px;
  }
  .employment-wrapper {
    align-items: flex-start;
    // .employment {
    //   gap: 0;
    //   .ant-form-item {
    //     width: 220px;
    //   }
    // }
  }
  .insureds-birthday {
    .ant-form-item {
      width: 100%;
      //   &:last-child {
      //     width: auto;
      //     min-width: 160px;
      //   }
    }
    .ant-radio-group {
      label {
        width: 100%;
      }
    }
  }
  .additional-fields {
    padding: 24px 24px 0 24px;
    margin-bottom: 24px;
    background-color: #f6f4f4;
    border-radius: 4px;
  }
  .lab-wrapper {
    .ant-form-item {
      width: 100%;
      label {
        width: 100%;
      }
      //   &:first-child {
      //     width: auto;
      //     min-width: 160px;
      //   }
    }
  }
  .label-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  .ellipsis {
    .ant-form-item-label {
      label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }
  .max-w-50 {
    max-width: 50%;
  }
  .max-w-33 {
    max-width: 33.3333333333333333%;
  }
  .flex.flex-column {
    gap: 0;
  }
  .divider {
    margin: 1em 0 2rem;
    border-top: 0;
    width: 100%;
    &::before,
    &::after {
      border-top: 1px solid rgba(51, 51, 51, 0.1);
    }
    svg {
      path {
        fill: #1890ff;
      }
    }
  }
  .ant-input-group-compact {
    gap: 0;
  }
}

.rapid .ant-tabs .ant-tabs-nav-wrap {
  background-color: #edf2f7;
  // border: 1px solid #cbd5e0;
}

.vertical-table {
  .ant-descriptions-item-label {
    padding: 10px 20px;
    white-space: nowrap;
    // width: 100%;
    max-width: 200px;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      white-space: nowrap;
    }
  }
  .ant-descriptions-item-content {
    padding: 0;
    span:not(span span) {
      display: flex;
      align-items: center;
    }
    span {
      .ant-form-item {
        margin-bottom: 0;
        border-right: 1px solid #f0f0f0;
        margin-right: 0.5rem;
        padding: 0.5rem 0.5rem 0.5rem 0;
        &:last-child {
          margin-right: 0;
        }
        &:first-child {
          margin-left: 0.5rem;
        }
      }
    }
  }
}
.description-label {
  display: flex;
  align-items: center;
  section {
    width: 100%;
    max-width: 200px;
  }
  div {
    display: flex;
    align-items: center;
    width: 100%;
    span {
      width: 100%;
      padding-left: 10px;
    }
  }
}
.pat-info {
  .ant-row:not(.ant-row .ant-row) {
    gap: 1rem;
    flex-flow: unset;
  }
  .ant-col {
    width: 100%;
    flex: unset;
    max-width: unset;
  }
}
.app-page__content {
  margin-top: 0rem;
  position: relative;
  .ant-steps-horizontal {
    max-width: 400px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    margin: 0 auto;
    margin-top: 2rem;
  }
  .steps-content {
    margin-top: 1rem;
    background-color: white;
  }
}
@media (min-width: 1200px) {
  .main-menu-sections {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .section-panel {
    .main-menu-sections {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}
@media (max-width: 1350px) {
  .ant-col-4 {
    max-width: 25%;
  }
}
