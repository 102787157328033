.error-message {
  &:empty {
    display: none;
  }
  margin-bottom: 1rem;
  .ant-alert-message {
    color: #ff4d4f;
    font-weight: 600;
  }
  p {
    color: #ff4d4f;
    margin-bottom: 0rem;
  }
}

.warning-message {
  &:empty {
    display: none;
  }
  margin-bottom: 1rem;
  .ant-alert-message {
    color: rgb(141, 43, 11);
    font-weight: 600;
  }
  p {
    color: rgb(141, 43, 11);
    margin-bottom: 0rem;
  }
}
