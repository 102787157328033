.ant-table-wrapper {
  .ant-form-item-label {
    display: none;
  }
}

.ant-table-expanded-row-fixed {
  padding: 10px;
  .ant-space-item {
    .ambulance {
      padding: 10px;
      border: 1px dashed #3333331c;
      .ant-form-item {
        padding: 0;
      }
    }
  }
}

.ambulance {
  margin-top: 10px;
}

.psysician-checkbox {
  margin-bottom: auto;
  margin-top: 0.5rem;
  padding-bottom: 1rem;
  label.ant-radio-wrapper {
    justify-content: flex-start;
    width: auto;
  }
  .ant-radio-wrapper {
    flex-wrap: unset;
  }
  & + .ant-row {
    width: 100%;
  }
}

.no-border-section {
  border: none !important;
}

.section-wrapper {
  width: 100%;

  section {
    padding: 5px;
    border: 1px solid #333;
    width: 100%;
  }
}

.flex-column {
  flex-direction: column;
}

.w-50 {
  width: 50% !important;
}

.w-30 {
  width: 30% !important;
}

.w-33 {
  width: 33% !important;
}

.w-37 {
  width: 37% !important;
}

.w-67 {
  width: 67% !important;
}

.bold {
  font-weight: bold;
}

.font-size-small {
  text-transform: none;
}

.flex-align-bottom {
  display: flex;
  align-items: end;
}

.flex-align-start {
  display: flex;
  align-items: start;
}

// Dental Items
.itemD1 {
  grid-area: itemD1;
}
.itemD2 {
  grid-area: itemD2;
}
.itemD3 {
  grid-area: itemD3;
}
.itemD4 {
  grid-area: itemD4;
}
.itemD5 {
  grid-area: itemD5;
}
.itemD6 {
  grid-area: itemD6;
}
.itemD7 {
  grid-area: itemD7;
}
.itemD8 {
  grid-area: itemD8;
}

.grid-container-dental-1 {
  display: grid;
  grid-template-areas:
    'itemD1 itemD2'
    'itemD3 itemD4'
    'itemD5 itemD6'
    'itemD7 itemD8';
  grid-template-columns: 50% calc(50% + 0.5px);
}

// Split in Third
.itemD9 {
  grid-area: itemD9;
}
.itemD10 {
  grid-area: itemD10;
}
.itemD11 {
  grid-area: itemD11;
}

.grid-container-dental-third {
  display: grid;
  grid-template-areas: 'itemD11 itemD12 itemD13';
  grid-template-columns: 34% 33% calc(33% + 0.5px);
}

// Second section
.itemD41 {
  grid-area: itemD41;
}
.itemD42 {
  grid-area: itemD42;
}
.itemD43 {
  grid-area: itemD43;
}
.itemD44 {
  grid-area: itemD44;
}
.itemD45 {
  grid-area: itemD45;
}
.itemD46 {
  grid-area: itemD46;
}
.itemD47 {
  grid-area: itemD47;
}
.itemD48 {
  grid-area: itemD48;
}
.itemD49 {
  grid-area: itemD49;
}
.itemD410 {
  grid-area: itemD410;
}

.grid-container-dental-2 {
  display: grid;
  grid-template-areas:
    'itemD41 itemD41 itemD42'
    'itemD43 itemD43 itemD44'
    'itemD45 itemD46 itemD47'
    'itemD48 itemD48 itemD48'
    'itemD49 itemD49 itemD410';
  grid-template-columns: 33% 33% calc(34% + 0.5px);
}

// Third
.itemD51 {
  grid-area: itemD51;
}
.itemD52 {
  grid-area: itemD52;
}
.itemD53 {
  grid-area: itemD53;
}
.itemD54 {
  grid-area: itemD54;
}
.itemD55 {
  grid-area: itemD55;
}

.grid-container-dental-3 {
  display: grid;
  grid-template-areas:
    'itemD51 itemD51 itemD51'
    'itemD52 itemD52 itemD52'
    'itemD53 itemD54 itemD55';
  grid-template-columns: 33% 33% calc(34% + 0.5px);
}

// Fourth
.itemD61 {
  grid-area: itemD61;
}
.itemD62 {
  grid-area: itemD62;
}
.itemD63 {
  grid-area: itemD63;
}
.itemD64 {
  grid-area: itemD64;
}
.itemD65 {
  grid-area: itemD65;
}
.itemD66 {
  grid-area: itemD66;
}
.itemD67 {
  grid-area: itemD67;
}

.grid-container-dental-4 {
  display: grid;
  grid-template-areas:
    'itemD61 itemD61'
    'itemD62 itemD62'
    'itemD63 itemD64'
    'itemD65 itemD66'
    'itemD67 itemD67';
  grid-template-columns: 50% calc(50% + 0.5px);
  border-left: 0!important;
  border-top: 0!important;
  border-bottom: 0!important;
}

.itemDS9 {
  grid-area: itemDS9;
}
.itemDS10 {
  grid-area: itemDS10;
}

.grid-container-dental-5 {
  display: grid;
  grid-template-areas: 'itemDS9 itemDS10';
  grid-template-columns: 50% calc(50% + 0.5px);
}

// First left part
.itemDS90 {
  grid-area: itemDS90;
}
.itemDS91 {
  grid-area: itemDS91;
}
.itemDS92 {
  grid-area: itemDS92;
}
.itemDS93 {
  grid-area: itemDS93;
}
.itemDS94 {
  grid-area: itemDS94;
}
.itemDS95 {
  grid-area: itemDS95;
}
.itemDS96 {
  grid-area: itemDS96;
}
.itemDS96a {
  grid-area: itemDS96a;
}
.itemDS97 {
  grid-area: itemDS97;
}
.itemDS98 {
  grid-area: itemDS98;
}
.itemDS99 {
  grid-area: itemDS99;
}
.itemDS910 {
  grid-area: itemDS910;
}
.itemDS911 {
  grid-area: itemDS911;
}
.itemDS912 {
  grid-area: itemDS912;
}

.grid-container-dental-6 {
  display: grid;
  grid-template-areas:
    'itemDS90 itemDS90 itemDS90'
    'itemDS91 itemDS91 itemDS91'
    'itemDS92 itemDS92 itemDS92'
    'itemDS93 itemDS93 itemDS93'
    'itemDS94 itemDS94 itemDS94'
    'itemDS95 itemDS95 itemDS95'
    'itemDS96 itemDS96 itemDS96'
    'itemDS96a itemDS96a itemDS96a'
    'itemDS97 itemDS98 itemDS99'
    'itemDS910 itemDS911 itemDS911'
    'itemDS912 itemDS912 itemDS912';
  grid-template-columns: 33% 33% calc(34% + 0.5px);
}
.itemDS101, .itemDS90, .itemDS93 {
  display: flex;
  align-items: center;
}
.min-height-90px {
  min-height: 90px;
}
.min-height-170px {
  min-height: 170px;
}
// .itemDS92 {
//   padding-top: 23px!important;
// }
// .itemDS103 {

// }
// First right part
.itemDS101 {
  grid-area: itemDS101;
}
.itemDS102 {
  grid-area: itemDS102;
}
.itemDS103 {
  grid-area: itemDS103;
}
.itemDS104 {
  grid-area: itemDS104;
}
.itemDS105 {
  grid-area: itemDS105;
}
.itemDS106 {
  grid-area: itemDS106;
}
.itemDS107 {
  grid-area: itemDS107;
}
.itemDS108 {
  grid-area: itemDS108;
}
.itemDS109 {
  grid-area: itemDS109;
}
.itemDS1010 {
  grid-area: itemDS1010;
}
.itemDS1011 {
  grid-area: itemDS1011;
}
.itemDS1012 {
  grid-area: itemDS1012;
}
.itemDS1013 {
  grid-area: itemDS1013;
}
.itemDS1014 {
  grid-area: itemDS1014;
}

.grid-container-dental-7 {
  display: grid;
  grid-template-areas:
    'itemDS101 itemDS101 itemDS101'
    'itemDS102 itemDS102 itemDS102'
    'itemDS103 itemDS104 itemDS105'
    'itemDS106 itemDS107 itemDS107'
    'itemDS108 itemDS108 itemDS108'
    'itemDS109 itemDS109 itemDS1010'
    'itemDS1011 itemDS1011 itemDS1011'
    'itemDS1012 itemDS1013 itemDS1014';
  grid-template-columns: 33% 33% calc(34% + 0.5px);
}

.grid-container-dental-1 > div,
.grid-container-dental-2 > div,
.grid-container-dental-3 > div,
.grid-container-dental-4 > div,
.grid-container-dental-5 > div,
.grid-container-dental-6 > div,
.grid-container-dental-7 > div {
  text-align: left;
  overflow: hidden;
  border: 1px solid black;
  padding: 5px;
  box-sizing: border-box;
  padding-bottom: 15px;
}

.grid-container-dental-third > div {
  text-align: left;
  overflow: hidden;
  padding: 5px;
  box-sizing: border-box;
  padding-bottom: 15px;
  display: flex;
  width: 100%;
}

.no-padding {
  padding: 0px;
}

.section-padding {
  padding: 5px 5px 15px 5px;
}

.padding-5 {
  padding: 5px !important;
}
.padding-1-5 {
  padding: 1px 5px !important;
  font-size: 12px;
  min-height: 24px;
}
.itemDS96a, .itemDS102 {
  padding-bottom: 12px!important;
}
.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.flex-d-column {
  flex-direction: column;
}

.no-border {
  border: none !important;
}

.position-bottom {
  display: flex;
  align-items: end;
}

.no-bl {
  border-left: 0px !important;
}

.mr-5 {
  margin-right: 5px;
}

.w-100_05 {
  width: calc(100% + 0.5px) !important;
}

.itemDS91 {
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0 !important; /* Remove margin for the first checkbox */
  }
}
