.PageViewerPagination__ {
  &container {
    display: flex;
    background-color: #fff;
    align-items: center;
    //border-bottom: 1px solid #edf2f7;
    margin-bottom: 0.5rem;
    // position: absolute;
    // top: -10px;
    p {
      vertical-align: middle;
      margin-bottom: 0px;
      margin-left: 10px;
    }
  }
}
