@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

html {
  font-size: 14px;
}

body {
  overflow: hidden;
  overflow-x: auto;
}

body.rapid {
  margin: 0;
  font-family: 'Inter', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--white);
  color: var(--neutral-900);
  // overflow: auto;
  // overflow-x: auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: bold;
    font-size: $text-base;
  }

  .rapid-header__user-popover {
    margin-top: $base-spacing-half;

    .rapid-avatar-wrapper {
      padding-bottom: $base-spacing;
      // margin-bottom: $base-spacing;
      border-bottom: 1px solid var(--border-color);
      & + .ant-list {
        .ant-list-header {
          border-bottom: none;
          padding: 0;
          font-weight: bold;
          color: var(--neutral-900);
        }
        margin: 0.5rem 0;
        .ant-list-item {
          border-bottom: none;
          padding: 0px 16px;
          padding-left: 35px;
          color: var(--neutral-700);
        }
      }
    }

    .rapid-button {
      width: 100%;
    }
  }

  .footer {
    background-color: #ffffff;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    // margin-top: 5rem;
    border-top: 1px solid #cbd5e052;
    .poweredByText {
      font-size: 15px;
      font-weight: 300;
      color: #5d5d5d;
      opacity: 0.5;
    }
  }
}

main {
  display: block;
}

strong {
  font-weight: 600;
}

.app {
  position: relative;
  //display: flex;
  padding-left: $menu-width;
  height: 100vh;
  background-color: var(--white);
  //overflow: hidden;

  .app-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;

    .rapid-header {
      justify-content: flex-start;
      z-index: 9;
      .logo-img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 0px;
        height: 100%;
        max-height: 50px;
        width: auto;
        overflow: visible;
      }
      .desktop-links {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-left: 2rem;
        a {
          color: rgba(0, 0, 0, 0.85);
          text-transform: uppercase;
          &.active {
            font-weight: bold;
            color: #1890ff;
          }
        }
      }
      .rapid-header__right {
        display: flex;
        align-items: center;
        justify-content: right;
        gap: $base-spacing-half;
        flex: 1 1 auto;

        .about-button {
          border: none;
          background-color: transparent;
          padding: 0;
          margin-left: auto;
        }

        .about-modal-list {
          dd {
            word-break: normal;
          }
        }
      }

      .rapid-header__user {
        border-left: 1px solid var(--border-color);
        padding-left: $base-spacing-half;

        button[data-state='open'] {
          .rapid-avatar-wrapper .rapid-avatar {
            background-color: var(--primary-700);
          }
        }
      }

      .rapid-header__user-popover-button {
        padding: 0;
        border: 0;
        background-color: transparent;
        border-radius: $rounded-full;
        cursor: pointer;

        &:focus {
          outline: none;
          box-shadow: var(--shadow-outline);
        }

        &:hover {
          .rapid-avatar-wrapper .rapid-avatar {
            background-color: var(--primary-700);
          }
        }
      }

      h1 {
        margin: 0;
        font-size: 8px;
        height: 10px;
        font-weight: 300;
        line-height: 12px;
        width: 160px;
        position: absolute;
        left: 50%;
        margin-left: -80px;
        clip: unset;
        margin-top: 35px;
        text-align: center;
      }

      .logo {
        height: 2rem;
        width: auto;
      }
    }

    .app__content {
      //flex-grow: 1;
      display: flex;
      flex-direction: column;
      //overflow-y: auto;
      //overflow-x: hidden;
      //background-color:var(--white);
      background-color: #ffffff;
      overflow: auto;
      //padding-bottom: 60px;
      height: 100%;

      main {
        flex-grow: 1;
        padding: $base-spacing $base-spacing;
        padding-bottom: 0;
        gap: $base-spacing * 2;
        .base-form-actions {
          margin-top: $base-spacing;
        }
      }

      .app-breadcrumb-wrapper .rapid-breadcrumb {
        border-bottom: 1px solid $border-color;
        padding-bottom: $base-spacing-half;
      }

      .app__footer {
        margin-top: $base-spacing;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--neutral-700);
      }
    }
  }
}

.ant-modal.large {
  width: 100% !important;
}

.label-top-btn {
  button {
    span {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-direction: column-reverse;
    }
  }
}
