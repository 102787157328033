.improved-form-wrapper {
  padding: 2rem;
  padding-right: 0;

  .flex {
    gap: 1rem;
  }

  .content-wrapper {
    display: flex;
    align-items: flex-start;

    .ant-steps-vertical {
      max-width: 250px;
    }

    .steps-content {
      flex: 1 1 auto;
      margin: 0 auto;
      margin-left: 4rem;
      overflow: hidden;
      border: 1px solid #d5d5d5;
      background-color: white;
      padding: 3rem;
      border-radius: 20px;

      h1 {
        font-size: 25px;
        padding: 0em 5px 0.5em 0;
        text-transform: uppercase;
      }

      h2 {
        font-size: 22px;
        font-weight: 600;
        text-align: left;
        margin-bottom: 1rem;
        margin-top: 1rem;
        // text-decoration: underline;
        text-transform: uppercase;
        opacity: 0.8;
      }

      .ant-form-item-label {
        padding-bottom: 5px;
        margin-top: 0;
      }

      label {
        font-size: 12px;
        height: initial;
        // &.ant-radio-button-wrapper {
        //   &:hover {
        //     background-color: rgba(0, 0, 0, 0.05);
        //   }
        // }
      }
    }

    .ant-form-item {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: 'tnum', 'tnum';
      margin-bottom: 18px;
      vertical-align: top;
      // width: unset;
    }
  }

  .steps-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
    gap: 1rem;
  }

  .other-coverage-btns {
    display: flex;
    align-items: center;

    p {
      width: 100%;
      white-space: nowrap;
    }

    .ant-checkbox-group {
      display: flex;
      align-items: center;

      label {
        width: 200px;
        //margin-left: 30px;
      }
    }
  }

  .diagnosis-code-list {
    label {
      white-space: nowrap;
    }
  }

  .signature-dields {
    align-items: flex-end;

    section {
      width: 50%;
    }
  }

  .check-application-boxes {
    .ant-checkbox-group {
      display: flex;
      align-items: center;
    }

    .ant-form-item-control {
      .ant-radio-button {
        white-space: nowrap;
      }
    }
  }

  .ant-picker {
    width: 100%;
  }

  .program-radio-btns {
    .ant-radio-group {
      justify-content: space-between;
      gap: 20px;

      label {
        width: 100%;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        padding: 10px 20px;
        height: unset;
        line-height: 1.4;
        text-align: center;
        height: 75px;
        align-items: center;
        justify-content: center;
        font-size: 12px;

        &::before {
          display: none;
        }
      }
    }
  }

  .sex-and-relationship {
    // .ant-form-item {
    //   &:first-child {
    //     width: auto;
    //     min-width: 160px;
    //   }
    // }
    .ant-radio-group {
      label {
        width: 100%;
      }
    }
  }

  .ant-radio-group {
    label {
      justify-content: center;
    }
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    top: 0px;
  }

  .employment-wrapper {
    align-items: flex-start;
    // .employment {
    //   gap: 0;
    //   .ant-form-item {
    //     width: 220px;
    //   }
    // }
  }

  .insureds-birthday {
    .ant-form-item {
      width: 100%;
      //   &:last-child {
      //     width: auto;
      //     min-width: 160px;
      //   }
    }

    .ant-radio-group {
      label {
        width: 100%;
      }
    }
  }

  .additional-fields {
    padding: 24px 24px 0 24px;
    margin-bottom: 24px;
    background-color: #f6f4f4;
    border-radius: 4px;
  }

  .lab-wrapper {
    .ant-form-item {
      width: 100%;

      label {
        width: 100%;
      }

      //   &:first-child {
      //     width: auto;
      //     min-width: 160px;
      //   }
    }
  }

  .label-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .ellipsis {
    .ant-form-item-label {
      label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }

  .max-w-50 {
    max-width: 50%;
  }

  .max-w-33 {
    max-width: 33.3333333333333333%;
  }

  .flex.flex-column {
    gap: 0;
  }

  .divider {
    margin: 1em 0 2rem;
    border-top: 0;
    width: 100%;

    &::before,
    &::after {
      border-top: 1px solid rgba(51, 51, 51, 0.1);
    }

    svg {
      path {
        fill: #1890ff;
      }
    }
  }

  .ant-input-group-compact {
    gap: 0;
  }

  .rapid-button {
    span {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .additional-fees {
    margin-left: auto;
    margin-top: 1rem;

    .ant-form-item {
      margin-bottom: 0;
    }

    .total-fee {
      margin-top: 1rem;
    }
  }
}

.margin-top-15 {
  margin-top: 5px;
}

.width-40 {
  width: 40%;
}

.padding-none {
  padding: 0px;
}

.missing-tooth-info {
  .ant-checkbox-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;

    .ant-checkbox-wrapper {
      width: 6%;
      margin: 0;
      height: 46px;
    }
  }

  #basic_33_mis_teeth_info {
    .ant-checkbox-wrapper {
      flex-direction: column;
      align-items: center;

      .ant-checkbox {
        & + span {
          padding: 0;
          margin-top: 3px;
        }
      }
    }
  }
}

.rapid .ant-tabs .ant-tabs-nav-wrap {
  background-color: #edf2f7;
  // border: 1px solid #cbd5e0;
}

.vertical-table {
  .ant-descriptions-item-label {
    padding: 10px 20px;
    white-space: nowrap;
    // width: 100%;
    max-width: 200px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      white-space: nowrap;
    }
  }

  .ant-descriptions-item-content {
    padding: 0;

    span:not(span span) {
      display: flex;
      align-items: center;
    }

    span {
      .ant-form-item {
        margin-bottom: 0;
        border-right: 1px solid #f0f0f0;
        margin-right: 0.5rem;
        padding: 0.5rem 0.5rem 0.5rem 0;

        &:last-child {
          margin-right: 0;
        }

        &:first-child {
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.description-label {
  display: flex;
  align-items: center;

  section {
    width: 100%;
    max-width: 200px;
  }

  div {
    display: flex;
    align-items: center;
    width: 100%;

    span {
      width: 100%;
      padding-left: 10px;
    }
  }
}

.pat-info {
  .ant-row:not(.ant-row .ant-row) {
    gap: 1rem;
    flex-flow: unset;
  }

  .ant-col {
    width: 100%;
    flex: unset;
    max-width: unset;
  }
}

.app-page__content {
  margin-top: 0rem;
  position: relative;

  //padding-bottom: 2rem;
  //height: calc(100vh - 195px);
  .form-actions {
    justify-content: flex-end;
    padding: 1rem 3rem;
    position: sticky;
    bottom: 0;
    left: 0;
    width: calc(100% + 4rem);
    margin-left: -2rem;
    background-color: white;
    // box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.1), 0 0px 2px 0 rgba(0, 0, 0, 0.2);
    border-top: 1px solid #cbd5e0;
    z-index: 8;
  }

  .ant-steps-horizontal {
    max-width: 400px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    margin: 0 auto;
    margin-top: 2rem;
  }

  .steps-content {
    margin-top: 1rem;
    background-color: white;
  }
}

// .main-menu {
//   .main-menu-sections {
//     .navigation-card:last-child {
//       filter: grayscale(100%);
//       pointer-events: none;
//     }
//   }
// }
// .main-menu {
//   .main-menu-sections {
//     .navigation-card:first-child {
//       display: none;
//     }
//     .main-menu-sections {
//       .navigation-card:first-child {
//         display: block;
//       }
//     }
//   }
// }
.section-panel {
  .main-menu-sections {
    .navigation-card {
      filter: unset !important;
      pointer-events: unset !important;
    }
  }
}
.navigation-card .navigation-card__icon {
  background-color: #cbd5e045;
  border-radius: 6px;
}
.navigation-card .navigation-card__icon:before {
  display: none;
}
.navigation-card--active .navigation-card__icon:before {
  display: none;
}
.navigation-card .navigation-card__icon svg {
  opacity: 0.8;
  padding: 8px;
}
.navigation-card--active .navigation-card__icon svg {
  opacity: 0.8;
  stroke: var(--neutral-900);
  height: 3rem;
  left: 0;
  padding: 8px;
  position: absolute;
  top: 0;
  transition: all 0.25s ease-in;
  width: 3rem;
  z-index: 1;
}

.rapid-header {
  position: relative;

  .rapid-header__left {
    h1 {
      & + svg {
        position: absolute;
        left: 50%;
        margin-left: -2%;
        margin-top: 0;
        width: auto;
        max-width: 105px;
        height: 40px;
        overflow: visible;
      }
    }

    button[aria-label='Hide sidebar'] {
      .icon-btn__icon {
        background-image: url('../../assets/close-menu.svg');
        background-position: center;
        background-repeat: no-repeat;

        svg {
          display: none;
        }
      }
    }
  }
}

.sidebar-multilevel-container .sidebar-multilevel {
  background-color: #ffffff;
  z-index: 10;

  .sidebar-multilevel__header {
    padding-top: 1rem;

    .sidebar-multilevel__button {
      display: none;
    }
  }
}

.rapid-stack {
  .ant-affix {
    z-index: 8;

    .app-breadcrumb-wrapper {
      background-color: #fbfbfb;

      .rapid-breadcrumb {
        padding-top: 0.5rem;
      }
    }
  }
}

.ant-dropdown-menu {
  .ant-checkbox-group {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 1rem;
  }
}

.search-form-wrapper {
  background-color: white;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: sticky;
}

.app-page__back {
  display: none;
}

.header-checkbox-btns {
  .ant-checkbox-group {
    display: flex;
    align-items: stretch;

    .ant-checkbox {
      display: none;
    }

    label {
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      padding: 10px 20px;
      height: unset;
      line-height: 1.4;
      text-align: center;
      height: 75px;
      min-height: 75px;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      cursor: pointer;
      flex-wrap: initial;

      &:hover {
        border: 1px solid #a0a0a0;
        color: black;
      }
    }

    label.ant-checkbox-wrapper-checked {
      background: #7f2a87d6;
      color: white;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      padding: 10px 20px;
      height: unset;
      line-height: 1.4;
      text-align: center;
      height: 75px;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }
  }
}

.sidebar-multilevel__nav .nav-item-group.active .nav-item-group__icon svg,
.sidebar-multilevel__nav .nav-item-group.active .nav-item__icon svg,
.sidebar-multilevel__nav .nav-item-group.nav-item--active .nav-item-group__icon svg,
.sidebar-multilevel__nav .nav-item-group.nav-item--active .nav-item__icon svg,
.sidebar-multilevel__nav .nav-item-group[data-active='true'] .nav-item-group__icon svg,
.sidebar-multilevel__nav .nav-item-group[data-active='true'] .nav-item__icon svg,
.sidebar-multilevel__nav .nav-item.active .nav-item-group__icon svg,
.sidebar-multilevel__nav .nav-item.active .nav-item__icon svg,
.sidebar-multilevel__nav .nav-item.nav-item--active .nav-item-group__icon svg,
.sidebar-multilevel__nav .nav-item.nav-item--active .nav-item__icon svg,
.sidebar-multilevel__nav .nav-item[data-active='true'] .nav-item-group__icon svg,
.sidebar-multilevel__nav .nav-item[data-active='true'] .nav-item__icon svg {
  stroke: var(--sidebar-multilevel-button-text-color);
}

.sidebar-multilevel__nav .nav-item .nav-item-group__icon.nav-item-group__icon--section svg,
.sidebar-multilevel__nav .nav-item .nav-item__icon svg,
.sidebar-multilevel__nav .nav-item-group .nav-item-group__icon.nav-item-group__icon--section svg,
.sidebar-multilevel__nav .nav-item-group .nav-item__icon svg {
  stroke: var(--sidebar-multilevel-button-text-color);
}

.sidebar-multilevel__nav li:nth-child(1),
.sidebar-multilevel__nav li:nth-child(2),
.sidebar-multilevel__nav li:nth-child(4),
.sidebar-multilevel__nav li:nth-child(6) {
  .nav-item-group,
  .nav-item {
    &:hover {
      svg {
        stroke: white;
      }
    }
  }
}

.sidebar-multilevel__nav li:nth-child(3),
.sidebar-multilevel__nav li:nth-child(5),
.sidebar-multilevel__nav li:nth-child(7),
.sidebar-multilevel__nav li:last-child {
  .nav-item-group,
  .nav-item {
    &:hover {
      svg {
        fill: white;
      }
    }
  }
}

.sidebar-multilevel__nav .nav-item-group:hover .nav-item-group__icon.nav-item-group__icon--section,
.sidebar-multilevel__nav .nav-item-group:hover .nav-item__icon,
.sidebar-multilevel__nav .nav-item:hover .nav-item-group__icon.nav-item-group__icon--section,
.sidebar-multilevel__nav .nav-item:hover .nav-item__icon {
  background-color: #636363;
}

a[href='/hcfa'] {
  &:hover {
    svg {
      fill: #47004c !important;
    }
  }
}
a[href='/'] {
  &:hover {
    svg {
      stroke: var(--white) !important;
    }
  }
}
a[href='/user-profile-reports'] {
  &:hover {
    svg {
      stroke: var(--white) !important;
    }
  }
}
a[href='/archive'] {
  &:hover {
    svg {
      fill: #636363 !important;
    }
  }
}
a[href='/user-profile-reports'] {
  &:hover {
    svg {
      fill: #636363 !important;
    }
  }
}

.sidebar-multilevel-container .sidebar-multilevel .sidebar__nav .nav-item-group.active .nav-item-group__icon svg,
.sidebar-multilevel-container .sidebar-multilevel .sidebar__nav .nav-item-group.active .nav-item__icon svg,
.sidebar-multilevel-container
  .sidebar-multilevel
  .sidebar__nav
  .nav-item-group.nav-item--active
  .nav-item-group__icon
  svg,
.sidebar-multilevel-container .sidebar-multilevel .sidebar__nav .nav-item-group.nav-item--active .nav-item__icon svg,
.sidebar-multilevel-container
  .sidebar-multilevel
  .sidebar__nav
  .nav-item-group[data-active='true']
  .nav-item-group__icon
  svg,
.sidebar-multilevel-container .sidebar-multilevel .sidebar__nav .nav-item-group[data-active='true'] .nav-item__icon svg,
.sidebar-multilevel-container .sidebar-multilevel .sidebar__nav .nav-item.active .nav-item-group__icon svg,
.sidebar-multilevel-container .sidebar-multilevel .sidebar__nav .nav-item.active .nav-item__icon svg,
.sidebar-multilevel-container .sidebar-multilevel .sidebar__nav .nav-item.nav-item--active .nav-item-group__icon svg,
.sidebar-multilevel-container .sidebar-multilevel .sidebar__nav .nav-item.nav-item--active .nav-item__icon svg,
.sidebar-multilevel-container .sidebar-multilevel .sidebar__nav .nav-item[data-active='true'] .nav-item-group__icon svg,
.sidebar-multilevel-container .sidebar-multilevel .sidebar__nav .nav-item[data-active='true'] .nav-item__icon svg {
  color: var(--white);
}

.sidebar-multilevel__nav .nav-item-group.active,
.sidebar-multilevel__nav .nav-item-group.nav-item--active,
.sidebar-multilevel__nav .nav-item-group[data-active='true'],
.sidebar-multilevel__nav .nav-item.active,
.sidebar-multilevel__nav .nav-item.nav-item--active,
.sidebar-multilevel__nav .nav-item[data-active='true'] {
  background-color: #e9e9e9;
  border-color: #636363;
  color: var(--neutral-link-color);
}
.sidebar-multilevel__nav .nav-item-group:hover,
.sidebar-multilevel__nav .nav-item:hover {
  border-color: #636363;
}
.sidebar-multilevel-container
  .sidebar-multilevel
  .sidebar__nav
  .nav-item-group:hover
  .nav-item-group__icon.nav-item-group__icon--section
  svg,
.sidebar-multilevel-container .sidebar-multilevel .sidebar__nav .nav-item-group:hover .nav-item__icon svg,
.sidebar-multilevel-container
  .sidebar-multilevel
  .sidebar__nav
  .nav-item:hover
  .nav-item-group__icon.nav-item-group__icon--section
  svg,
.sidebar-multilevel-container .sidebar-multilevel .sidebar__nav .nav-item:hover .nav-item__icon svg {
  color: var(--white);
}

.sidebar__nav {
  li {
    &:last-child {
      margin-top: auto;
      // border-top: 1px solid #cbd5e0;
      // padding-top: 1rem;
    }
  }
}

.sidebar-multilevel-container .sidebar-multilevel .sidebar__nav .nav-item-group:focus,
.sidebar-multilevel-container .sidebar-multilevel .sidebar__nav .nav-item:focus {
  color: var(--sidebar-multilevel-nav-item-text-color--focus);
  outline: unset;
}

@media (min-width: 1200px) {
  .main-menu-sections {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .section-panel {
    .main-menu-sections {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}

.height_31a {
  height: 50px;
}
