@import '@datum-css/css/src/variables.scss';
@import './brand-colors';
@import './utilities';
@import './base';
@import './app';
@import './antd';
//components
@import './components/page';
@import './components/forms';
@import './components/form-error-list';
@import './components//service-list-table';
//Pages
@import './pages/search';
@import './pages/dashboard';
@import './pages/hcfa-paper';
@import './pages/ub04-paper';
@import './pages/home';
@import './pages/branding-page';
@import './pages/archive';
//themes
@import './themes/theme-branding';
@import './themes/hap-caresource';
@import './themes/truecare';

.app-page__content {
  .ant-space {
    z-index: 10;
  }
}

.ant-tabs-content-holder {
  overflow-y: auto;
}
