.app-page {
  display: flex;
  flex-direction: column;
  gap: $base-spacing-quarter;
  position: relative;
  .app-page__back {
    ul {
      margin: 0;

      li {
        margin: 0;

        button {
          border: none;
          padding: 0;
          padding-right: $base-spacing-quarter;

          svg {
            width: 1rem;
            height: 1rem;
          }

          &:focus {
            border: none !important;
            background-color: var(--white) !important;
          }

          &:hover {
            border: none !important;
            background-color: var(--white) !important;

            span {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .app-page__header--wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $base-spacing-quarter;
    margin-bottom: 1rem;
    .uploaded-document-icon {
      margin-top: 0.5em;
      margin-bottom: 1em;
      margin-left: auto;
      .ant-badge {
        button {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #4d9bff;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(255, 255, 255, 0.8);
          svg {
            font-size: 24px;
          }
          &:hover {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }
    .app-page__header {
      display: flex;
      align-items: center;
      gap: $base-spacing;
      margin-bottom: $base-spacing-half;
      h2.page__title {
        font-size: $text-2xl;
        text-transform: uppercase;
        font-weight: var(--bold);
        //color: var(--primary-900);

        display: flex;
        align-items: center;
        gap: 1rem;
      }
      .rapid-button-list {
        li {
          margin: 0;
        }
      }
    }

    .app-page__description {
      // padding-bottom: $base-spacing;
      font-size: $text-lg;
      max-width: calc(100% - 245px);
    }
  }
}
.app-page__header {
  position: relative;
}
.ant-drawer-content-wrapper {
  .ant-avatar {
    background: #1890ff;
  }
}
.simple-upload-doc {
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
