$shadow-outline-light: 0 0 0 3px rgba(133, 176, 254, 0.5);

//App's custom general properties
.app {
  padding-left: 0;
}
.ant-modal-mask {
  background-color: #00000054;
}
.rapid-dialog__overlay,
.rapid-alert-dialog__overlay {
  z-index: 10;
}

.rapid-dialog,
.rapid-alert-dialog {
  z-index: 15;
}

.back-url svg {
  height: 1.5rem;
}

.field-description {
  font-size: $text-sm;
}

.rapid-toggle-group-form-view-wrapper {
  position: absolute;
  right: 0;

  label.visually-hidden {
    clip: initial;
    height: auto;
    width: auto;
    margin: auto;
    overflow: initial;
    position: initial;
  }
}

.ant-input[disabled],
.ant-checkbox-disabled + span,
.ant-input-disabled,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
// .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder,
.ant-radio-button-wrapper-disabled,
.ant-radio-button-wrapper-disabled:first-child,
.ant-picker-input > input[disabled] {
  color: #000000a6;
}

.ant-divider-inner-text {
  b {
    color: var(--neutral-link-color);
  }
}
.itemDS109.no-bl {
  min-height: 126px;
}
.claim-wrapper {
  max-width: 1840px;
  margin: 0 auto;
}
.itemDS97, .itemDS98, .itemDS99, .itemDS96, .itemDS106, .itemDS107 {
  min-height: 65px;
}
.itemDS96a {
  min-height: 88px;
}

.itemDS102 {
  min-height: 138px;
}

.upload-claims {
  .rapid-button-list[data-position=right] {
    justify-content: center;
  }
}

@media (max-width: 1600px) {
    .min-height-90px {
      min-height: 150px!important;
  }
  .hcfa-form .form_label_color, .hcfa-form label {
    font-size: 10px!important;
  }
  .hcfa-form .ant-table.ant-table-small .ant-table-title, .hcfa-form .ant-table.ant-table-small .ant-table-footer, .hcfa-form .ant-table.ant-table-small .ant-table-thead > tr > th, .hcfa-form .ant-table.ant-table-small .ant-table-tbody > tr > td, .hcfa-form .ant-table.ant-table-small tfoot > tr > th, .hcfa-form .ant-table.ant-table-small tfoot > tr > td {
    font-size: 10px!important;
  }
  .ant-input, .ant-select-selection-item, .ant-form-item-explain, .ant-form-item-extra {
    font-size: 12px!important;
    min-height: 24px;
  }
  .itemDS109.no-bl {
    min-height: 129px;
  }
  .itemDS103 {
    min-height: 100px;
  }
}

@media (max-width: 1599px) {
  .itemDS102 {
    min-height: 162px;
  } 
}

@media (max-width: 1469px) {
  .itemDS102 {
    min-height: 166px;
  } 
}

@media (max-width: 1425px) {
  .itemDS97, .itemDS98, .itemDS99, .itemDS96, .itemDS106, .itemDS107 {
    min-height: 82px;
  }
  .itemDS109.no-bl {
    min-height: 146px;
  }
}
@media (max-width: 1150px) {

}