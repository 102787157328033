.ub-form-wrapper {
  border: 1px solid black;
  box-sizing: border-box;

  .inputs-wrapper {
    display: flex;
    align-items: stretch;
  }

  .remove-padding {
    padding: 0px !important;
  }

  .flex-top {
    display: flex;
    align-items: start;
  }

  .border-top {
    border-top: 1px solid black;
  }

  .border-bottom {
    border-bottom: 1px solid black;
  }

  section {
    padding: 5px;
    border: 1px solid black;
    width: 100%;
  }

  .no-border {
    border: none !important;
  }

  .h-100 {
    height: 100%;
  }

  .w-100 {
    width: 100% !important;
  }

  .w-20 {
    width: 20%;
  }

  .w-30 {
    width: 30%;
  }

  .w-33 {
    width: 33%;
  }

  .w-34 {
    width: 34%;
  }

  .w-40 {
    width: 40%;
  }

  .w-50 {
    width: 50%;
  }

  .w-60 {
    width: 60%;
  }

  .w-80 {
    width: 80%;
  }

  .w50-50 {
    section {
      &:first-child {
        width: 50%;
      }
      &:nth-child(2) {
        width: 50%;
      }
    }
  }

  .payer-name-style {
    section {
      &:first-child {
        width: 18%;
      }
      &:nth-child(2) {
        width: 16%;
      }
      &:nth-child(3) {
        width: 8%;
      }
      &:nth-child(4) {
        width: 8%;
      }
      &:nth-child(5) {
        width: 14%;
      }
      &:nth-child(6) {
        width: 14%;
      }
      &:nth-child(7) {
        width: 6%;
      }
      &:last-child {
        width: 16%;
      }
    }
  }

  .insured-name-style {
    section {
      &:first-child {
        width: 28%;
      }
      &:nth-child(2) {
        width: 11%;
      }
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(4) {
        width: 14%;
      }
      &:last-child {
        width: 22%;
        border-right: 1px solid white;
        box-shadow: 1px 0px 0px 0px black;
      }
    }
  }

  .w45-45-10 {
    section {
      &:first-child {
        width: 45%;
      }
      &:nth-child(2) {
        width: 45%;
      }
      &:last-child {
        width: 10%;
      }
    }
  }

  .w33-33-34 {
    section {
      &:first-child {
        width: 33%;
      }
      &:nth-child(2) {
        width: 33%;
      }
      &:last-child {
        width: 34%;
      }
    }
  }

  .w30-40-30 {
    section {
      &:first-child {
        width: 30%;
      }
      &:nth-child(2) {
        width: 40%;
      }
      &:last-child {
        width: 30%;
      }
    }
  }

  .w12-4-20-4-4-44-4-8 {
    section {
      &:first-child {
        width: 12%;
      }
      &:nth-child(2) {
        width: 10%;
      }
      &:nth-child(3) {
        width: 24%;
      }
      &:nth-child(4) {
        width: 4%;
      }
      &:nth-child(5) {
        width: 4%;
      }
      &:nth-child(6) {
        width: 40%;
      }
      &:nth-child(7) {
        width: 4%;
      }
      &:last-child {
        width: 2%;
      }
    }
  }

  .w40-40-20 {
    section {
      &:first-child {
        width: 40%;
      }
      &:nth-child(2) {
        width: 40%;
      }
      &:last-child {
        width: 20%;
      }
    }
  }
}

.vertical {
  flex-direction: column;
}

.red {
  color: red;
}

.parent-flex {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.parent section {
  flex: 1;
}

.child-flex {
  flex: 1;
}

.border-2 {
  border: 1px solid black !important;
}

.bt {
  border-top: 1px solid black !important;
}

.bt-2 {
  border-top: 2px solid black !important;
}

.bl {
  border-left: 1px solid black !important;
}

.br {
  border-right: 1px solid black !important;
}

.br-2 {
  border-right: 2px solid black !important;
}

.bl-2 {
  border-left: 2px solid black !important;
}

.bb {
  border-bottom: 1px solid black !important;
}

.bb-2 {
  border-bottom: 2px solid black !important;
}

.no-bt {
  border-top: 0px !important;
}

.no-bl {
  border-left: 0px !important;
}

.no-br {
  border-right: 0px !important;
}

.no-bb {
  border-bottom: 0px !important;
}

.border-l-r {
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.black-border {
  border: 1px solid black;
}

.cc_fields_wrapper {
  width: 50px;
}

.cc-flex {
  display: flex;
  flex-wrap: nowrap;
}

.padding-0 {
  padding: 0!important;
}
.border-0 {
  border: none!important
}

.cc-flex-warp {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 5px;
}

.cc_wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 5px;
}

//First row
.item1 {
  grid-area: hospital_name;
}
.item2 {
  grid-area: payto_name;
}
.item3 {
  grid-area: pat_cntl;
}
.item4 {
  grid-area: med_rec;
}
.item5 {
  grid-area: typeof_bill;
}
.item6 {
  grid-area: fed_tax_no;
}
.item7 {
  grid-area: statement;
}
.item8 {
  grid-area: seven;
}

.grid-container1 {
  display: grid;
  grid-template-areas:
    'hospital_name payto_name pat_cntl   pat_cntl   typeof_bill typeof_bill typeof_bill'
    'hospital_name payto_name med_rec    med_rec    typeof_bill typeof_bill typeof_bill'
    'hospital_name payto_name fed_tax_no fed_tax_no statement   seven   seven';
  grid-template-columns: 30% 40% 5% 7% 9% 5% calc(4% + 0.5px);
}

// Second row
.item9 {
  grid-area: pat_name;
}
.item10 {
  grid-area: pat_address;
}

.grid-container2 {
  display: grid;
  grid-template-areas: 'pat_name pat_address';
  grid-template-columns: 50% 50%;
}

// Third row
.item11 {
  grid-area: birth_p;
}
.item12 {
  grid-area: sex__11;
}
.item13 {
  grid-area: admissi;
}
.item14 {
  grid-area: dhr__16;
}
.item15 {
  grid-area: stat_17;
}
.item16 {
  grid-area: conditi;
}
.item17 {
  grid-area: acdt_st;
}
//.item18 { grid-area: field37; }

.grid-container3 {
  display: grid;
  //grid-template-areas: 'grid-container3-1';
  grid-template-columns: 50% 50%;
  .grid-container3-1 {
    display: grid;
    grid-template-areas: "birth_p sex__11 admissi dhr__16 ";
    grid-template-columns: 20% 15% 40% 25%;
    padding: 0;
    .patient-sex {
      padding: 0 10px;
    }
    & > div {
      border-right: 1px solid;
      border-left: 1px solid;
      label.form_label_color {
        padding: 5px 5px 0 5px;
      }
      &:last-child {
        border-right: 0;
      }
      &:first-child {
        border-left: 0;
      }
    }
  }
  .grid-container3-2 {
    display: grid;
    grid-template-areas: "stat_17 conditi acdt_st";
    grid-template-columns: 15% 70% 15%;
    padding: 0;
    & > div {
      border-right: 1px solid;
      border-left: 1px solid;
      label.form_label_color {
        padding: 5px 5px 0 5px;
      }
      &:last-child {
        border-right: 0;
      }
      &:first-child {
        border-left: 0;
      }
    }
  }
}

// Fourth row
.item19 {
  grid-area: occur_31;
  padding-bottom: 5px;
}
.item20 {
  grid-area: occur_32;
}
.item21 {
  grid-area: occur_33;
}
.item22 {
  grid-area: occur_34;
}
.grid-container4-wrapper {
  align-items: normal;
  display: flex;
  flex-wrap: wrap;
}

.grid-container4-1 {
  display: grid;
  grid-template-areas: 'occur_31 occur_32 occur_33 occur_34';
  grid-template-columns: 25% auto;
  width: 100%;
  border: 1px solid;
  & > div {
      border-right: 1px solid;
      border-left: 1px solid;
      label.form_label_color {
        padding: 5px 5px 0 5px;
      }
      &:last-child {
        border-right: 0;
      }
      &:first-child {
        border-left: 0;
      }
    }
}

// Fifth row
.item23 {
  grid-area: occur_35;
  padding-bottom: 5px;
}
.item24 {
  grid-area: occur_36;
}
.item25 {
  grid-area: occur_37;
}

.grid-container5-1 {
  display: grid;
  grid-template-areas: 'occur_35 occur_36 occur_37';
  grid-template-columns: 50% 42.5% 7.5%;
  width: 100%;
  border: 1px solid;
  & > div {
      border-right: 1px solid;
      border-left: 1px solid;
      label.form_label_color {
        padding: 5px 5px 0 5px;
      }
      &:last-child {
        border-right: 0;
      }
      &:first-child {
        border-left: 0;
      }
    }
}

// Six row
.item26 {
  grid-area: field_38;
}
.item27 {
  grid-area: value_39;
}
.item28 {
  grid-area: value_40;
}
.item29 {
  grid-area: value_41;
}

.grid-container6 {
  display: grid;
  width: 100%;
  grid-template-areas: 'field_38 value_39 value_40 value_41';
  grid-template-columns: 50% auto;
}

// Seventh row
.item30 {
  grid-area: player_n;
}
.item31 {
  grid-area: health_p;
}
.item32 {
  grid-area: real_inf;
}
.item33 {
  grid-area: asg__ben;
}
.item34 {
  grid-area: prior__p;
}
.item35 {
  grid-area: est_amou;
}
.item36 {
  grid-area: npi___56;
  border-right: 0!important;
  display: flex;
  align-items: center;
  padding-bottom: 5px!important;
}
.item37 {
  grid-area: item__37;
  border-left: 0!important;
  overflow: visible;
}

.grid-container7 {
  display: grid;
  grid-template-areas: 'player_n health_p real_inf asg__ben prior__p est_amou npi___56 item__37';
  grid-template-columns: 14% 14% 11% 11% 14% 14% 9% calc(13% + 1px);
}

.grid-container7.item31_update_b {
  div:not(:last-child) {
    display: flex;
    align-items: center;
    padding-bottom: 5px !important;
  }
}

.grid-container7.item31_update_b {
  div:not(:last-child) {
    display: flex;
    align-items: center;
    padding-bottom: 5px !important;
  }
}

.grid-container7.item31_update_b {
  div:not(:nth-last-child(2)):not(:last-child) {
    border-bottom: 0;
  }
}

.item37 {
  padding-bottom: 5px!important;
}
.grid-container7_addition.add-1 {
  .item36, .item37 {
    border-bottom: 2px solid;
  }
  .item34, .item35 {
    border-bottom: 0;
    display: flex;
    align-items: center;
    padding-bottom: 10px !important;
  }
}
.grid-container7_addition.add-2{
  .item30, .item31, .item32, .item33, .item34, .item35 {
    padding-top: 5px;
    padding-bottom: 0px;
    border-top: 0;
    border-bottom: 0;
  }
  .item31_update_b {
    overflow: visible;
    .flex-1:first-child {
      .item36, .item37 {
        border-top: 0;
      }
    }
    .flex-1:last-child {
      border-bottom: 1px solid;
      .item36, .item37 {
        border-bottom: 0;
      }
    }
  }
  .item36 {
    border-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    min-width: 150px;
  }
  .item37 {
    border-left: 0;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.fields-58-62 {
  .parent-flex:not(:last-child) {
    section {
      border-bottom: 0;
    }
  }
  .parent-flex:not(:first-child) {
    section {
      border-top: 0;
    }
  }
}

// Seventh_A row
.item37_a {
  grid-area: fiels37_a;
}
.item37_b {
  grid-area: fiels37_b;
}
.item37_c {
  grid-area: fiels37_c;
}

.grid-container7_a {
  display: grid;
  grid-template-areas: 'fiels37_a fiels37_b fiels37_c ';
  grid-template-columns: 28% 36% calc(36% + 1px);
  div {
    padding-bottom: 5px!important;
    label {
      line-height: 1;
    }
  }
}

// .item31_update_a {
//   grid-area: item31_update_a;
// }
.item31_update_b {
  grid-area: item31_update_b;
  padding: 0px;
}

.grid-container7_addition {
  padding: 0px;
  border: 0px;
  display: grid;
  grid-template-areas: 'item31_update_b';
  grid-template-columns: 100%;
}

// Eight row
.item38 {
  grid-area: fiels_38;
}
.item39 {
  grid-area: fiels_39;
}
.item40 {
  grid-area: fiels_40;
}
.item41 {
  grid-area: fiels_41;
}
.item42 {
  grid-area: fiels_42;
}
.item43 {
  grid-area: fiels_43;
}
.item44 {
  grid-area: fiels_44;
}
.item45 {
  grid-area: fiels_45;
}
.item46 {
  grid-area: fiels_46;
}
.item47 {
  grid-area: fiels_47;
}
.item48 {
  grid-area: fiels_48;
}

.grid-container8 {
  display: grid;
  grid-template-areas: 'fiels_38 fiels_39 fiels_40 fiels_41 fiels_42 fiels_43 fiels_44 fiels_45 fiels_46 fiels_47 fiels_48';
  grid-template-columns: 10% auto;
}

// Ninth row
.item49 {
  grid-area: fiels_49;
}
.item50 {
  grid-area: fiels_50;
}
.item51 {
  grid-area: fiels_51;
}
.item52 {
  grid-area: fiels_52;
}
.item53 {
  grid-area: fiels_53;
}
.item54 {
  grid-area: fiels_54;
}
.item55 {
  grid-area: fiels545;
}
.item56 {
  grid-area: fiels_56;
}
.item57 {
  grid-area: fiels_57;
}
.item58 {
  grid-area: fiels_58;
}
.item59 {
  grid-area: fiels_59;
}

.grid-container9 {
  display: grid;
  grid-template-areas: 'fiels_50 fiels_51 fiels_52 fiels_53 fiels_54 fiels545 fiels_56 fiels_57 fiels_58';
  grid-template-columns: 10% 9% 9% 9% 9% 9% 9% auto;
}

// Tenth row
.item60 {
  grid-area: fiels_60;
}
.item61 {
  grid-area: fiels_61;
}
.item62 {
  grid-area: fiels_62;
}
.item63 {
  grid-area: fiels_63;
}
.item64 {
  grid-area: fiels_64;
}

.grid-container10 {
  display: grid;
  grid-template-areas: 'fiels_60 fiels_61 fiels_62 fiels_63 fiels_64';
  grid-template-columns: 10% 18% 18% 18% auto;
  .form_label_color {
    padding: 0 5px;
  }
}

label[for="basic_71_pps"], label[for="basic_ub73_loc"], label[for="basic_UB75"] {
  margin-bottom: 5px!important;
}

// Child row
.item64_a {
  grid-area: fiels64_a;
}
.item64_b {
  grid-area: fiels64_b;
}
.item64_c {
  grid-area: fiels64_c;
}

.grid-container11 {
  display: grid;
  grid-template-areas: 'fiels64_a fiels64_b fiels64_c';
  grid-template-columns: 34% 33% calc(33% + 0.5px);
}

// Twelfth row
.item65 {
  grid-area: fiels65;
}
.item66 {
  grid-area: fiels66;
}

.grid-container12 {
  display: grid;
  grid-template-areas: 'fiels65 fiels66';
  grid-template-columns: 69% auto;
  grid-template-rows: auto auto;
}

.item121 {
  grid-area: field121;
  margin: 0px;
}
.item122 {
  grid-area: field122;
  margin: 0px;
}
.item123 {
  grid-area: field123;
  margin: 0px;
  padding-bottom: 15px!important;
}

.grid-container12a {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-areas:
    'field121 field123'
    'field122 field123';
  //grid-template-columns: 100%;
  grid-template-rows: auto auto;
  padding: 0px;
  margin: 0px;
}

// .field121 { grid-area: 1 / 1 / 2 / 2; }
// .field122 { grid-area: 1 / 2 / 3 / 3; }
// .field123 { grid-area: 2 / 1 / 3 / 2; }

//
.item_68 {
  grid-area: fiels68;
}
.item_69 {
  grid-area: fiels69;
}
.item_70 {
  grid-area: fiels70;
}
.item_71 {
  grid-area: fiels71;
}
.item_72 {
  grid-area: fiels72;
}
.item_73 {
  grid-area: fiels73;
}
.item_74 {
  grid-area: fiels74;
}
.item_75 {
  grid-area: fiels75;
}
.item_76 {
  grid-area: fiels76;
}
.item_77 {
  grid-area: fiels77;
}
.item_78 {
  grid-area: fiels78;
}
.item_79 {
  grid-area: fiels79;
}
.item_80 {
  grid-area: fiels80;
  .form_label_color {
    padding: 5px 5px 15px;
    border-bottom: 2px solid transparent!important;
    min-height: 47px;
  }
}
.item_81 {
  grid-area: fiels81;
}
.item_82 {
  grid-area: fiels82;
}
.item_83 {
  grid-area: fiels83;
}
.item_84 {
  grid-area: fiels84;
}
.item_85 {
  grid-area: fiels85;
}
.item_86 {
  grid-area: fiels86;
}

.grid-container13 {
  height: 100%;
  display: grid;
  grid-template-areas:
    'fiels68 fiels68 fiels69 fiels69 fiels70 fiels70 fiels80'
    'fiels71 fiels72 fiels73 fiels74 fiels75 fiels76 fiels80'
    'fiels77 fiels77 fiels78 fiels78 fiels79 fiels79 fiels80'
    'fiels81 fiels82 fiels83 fiels84 fiels85 fiels86 fiels80';
  grid-template-columns: auto;
  grid-template-rows: auto auto;
}

.item_87 {
  grid-area: fiels87;
  border-right: 2px solid black;
}
.item_88 {
  grid-area: fiels88;
}

.grid-container14 {
  display: grid;
  grid-template-areas: 'fiels87 fiels88';
  grid-template-columns: 65% auto;
}

.grid-container15 {
  display: grid;
  grid-template-areas: 'fiels87 fiels88';
  grid-template-columns: 50% calc(50% + 0.5px);
}

.grid-container1 > div,
.grid-container2 > div,
.grid-container3 > div,
.grid-container4 > div,
.grid-container5 > div,
.grid-container6 > div,
.grid-container7 > div,
.grid-container7_a > div,
.grid-container8 > div,
.grid-container9 > div,
.grid-container10 > div,
.grid-container11 > div,
.grid-container12 > div,
.grid-container12a > div,
.grid-container13 > div,
.grid-container14 > div,
.grid-container7_addition > div,
.grid-container15 > div {
  text-align: left;
  overflow: hidden;
  border: 1px solid black;
  padding: 5px;
  box-sizing: border-box;
  padding-bottom: 15px;
}

.grid-container12a > div {
  padding: 0px;
}

.grid-container13 > div {
  text-align: center;
}

.full_height {
  height: 100%;
}

.no-padding {
  padding: 0px !important;
}

.container7_a-border {
  border: 1px solid black;
  border-bottom: 0px;
}

.border {
  border: 1px solid black;
}

.flex_d_column {
  flex-direction: column;
}

.height_50 {
  height: 50%;
}

.section_76 {
  border-bottom: 2px solid black;
  padding: 5px 5px 15px 5px;
}

.padding {
  padding: 5px 5px 15px 5px;
}

.padding-5 {
  padding: 5px 5px 5px 5px;
}

.padding_t10 {
  padding-top: 10px;
}

.padding_b10 {
  padding-bottom: 10px;
}

.float-right {
  text-align: right;
}

.flex-1 {
  flex: 1;
}

.vertical_align_with_space {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.label_width {
  width: 20px;
}
