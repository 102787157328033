.dashboard-page {
  margin-top: 1rem;

  .search-title-checks-wrapper {
    h2 {
      span {
        opacity: 0.5;
      }

      small {
        font-weight: 300;
        font-size: 70%;
      }
    }
  }

  .search-form-wrapper {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }

  .ant-tabs-content-holder {
    border: none !important;
    padding: 0 !important;
  }

  .app-page__header--wrapper {
    display: none;
  }

  .ant-tabs-top > .ant-tabs-nav:before {
    display: none !important;
  }

  .dashboard-tabs {
    .ant-tabs-nav-wrap {
      background-color: transparent !important;
      justify-content: center;
    }

    .ant-tabs-tab {
      background-color: transparent;
      border: none;
      color: var(--text-color);
      opacity: 0.55;

      &:hover {
        background-color: transparent;
        color: var(--text-color);
        opacity: 1;
      }
    }

    .ant-tabs-tab-active {
      border-top-color: transparent !important;
      color: var(--text-color);
      opacity: 1;

      .ant-tabs-tab-btn {
        color: var(--text-color);
      }
    }

    .tab-title-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      p {
        margin-bottom: 0;
        &:last-child {
          line-height: 1;
          margin-bottom: 1rem;
          opacity: 0.6;
        }
      }
      svg {
        font-size: 25px;
        margin-bottom: 5px;
      }
    }
  }
  .ant-table-thead > tr > th {
    padding: 26px 16px;
  }
  .ant-table-thead {
    .ant-table-cell {
      text-transform: uppercase;
      font-weight: bold;
      opacity: 0.8;
      white-space: nowrap;
      .table-title {
        display: flex;
        align-items: center;
        font-size: 80%;
      }
      .anticon {
        margin-right: 7px;
        svg {
          font-size: 18px;
        }
      }
    }
  }

  .ant-table-cell {
    .claims-info {
      .status {
        .badge {
          font-size: 65%;
        }
      }
      div {
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    p {
      line-height: 1.2;
      margin-bottom: 7px;
      display: flex;
      align-items: center;
      justify-content: start;
      svg {
        margin-right: 5px;
        font-size: 17px;
        opacity: 0.7;
        min-width: 15px;
      }
      span {
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 350px;
        overflow: hidden;
      }
    }
    div {
      margin-bottom: 5px;
      &:last-child {
        margin-top: 0;
      }
    }
    b {
      opacity: 0.8;
      //display: flex;
      //align-items: center;
      margin-right: 5px;
      line-height: 14px;
      // margin-bottom: 5px;
      white-space: nowrap;
      display: none;
    }

    .link {
      display: block;
      color: purple;
      text-decoration: underline;
      cursor: pointer;
      font-size: 16px;
    }

    small {
      opacity: 0.7;
    }
  }
  .charts-wrapper {
    // display: flex;
    // align-items: flex-end;
    // gap: 2rem;
    // margin-bottom: 2rem;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    .chart {
      overflow: hidden;
      border: 1px solid #d5d5d5;
      background-color: white;
      padding: 2rem 0;
      border-radius: 20px;
      /* min-width: 30%; */
      width: 100%;
      h2 {
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 1rem;
      }
      min-width: 20%;
    }
  }
}
