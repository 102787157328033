.rapid {
  //tabs
  .ant-tabs {
    .ant-tabs-nav {
      margin: 0 !important;
    }

    .ant-tabs-tab {
      margin: 0;
      padding: $base-spacing-half $base-spacing;
      color: var(--primary-alt--light);
      box-sizing: border-box;
      border-top: 5px solid transparent !important;
      background-color: var(--neutral-200);
      font-weight: 600;
      height: 100%;
      border: none;
    }

    .ant-tabs-nav-wrap {
      background-color: var(--neutral-200);
    }

    .ant-tabs-content-holder {
      padding: $base-spacing;
      border: 1px solid var(--border-color);
      border-top: 0px;
      background-color: var(--white);
    }

    .ant-tabs-tab:focus,
    .ant-tabs-tab:hover {
      background-color: var(--neutral-300);
    }

    .ant-tabs-tab-active {
      border-bottom: none;
      border-top: 5px solid var(--primary-alt--light) !important;
      border-left: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
      background-color: var(--white);
      color: var(--primary-900);

      .ant-tabs-tab-btn {
        font-weight: 600;
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }

  //upload
  .ant-upload .ant-upload-btn {
    svg {
      width: 5rem;
      color: var(--primary-500);
    }

    &:hover {
      svg {
        color: var(--primary-900);
      }
    }
  }

  // custom update for CareSource
  .search-inputs-form .ant-form-item .ant-form-item-label label {
    font-weight: 0;
  }
  .ant-form-item-explain-warning {
    color: #fa6c14;
  }
  .ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border-color: #fa6c14;
  }
  .search-inputs-form .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
    box-shadow: none;
  }
}

.hcfa-form {
  .ant-form-small .ant-form-item-label > label {
    font-weight: bold;
  }
}
