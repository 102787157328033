.theme {
  a {
    color: var(--neutral-link-color);
  }
  table {
    button.icon-btn.icon-btn--primary-outline {
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
    }
  }
  .rapid-button--primary-primary {
    background-color: var(--primary-color);
  }
  .sidebar-multilevel__container .sidebar-multilevel .sidebar__nav .nav-item-group.active,
  .sidebar-multilevel__container .sidebar-multilevel .sidebar__nav .nav-item-group.nav-item--active,
  .sidebar-multilevel__container .sidebar-multilevel .sidebar__nav .nav-item-group[data-active='true'],
  .sidebar-multilevel__container .sidebar-multilevel .sidebar__nav .nav-item.active,
  .sidebar-multilevel__container .sidebar-multilevel .sidebar__nav .nav-item.nav-item--active,
  .sidebar-multilevel__container .sidebar-multilevel .sidebar__nav .nav-item[data-active='true'] {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    .nav-item__icon {
      svg {
        stroke: white;
        color: white;
      }
    }
  }
  .rapid-breadcrumb .rapid-breadcrumb__list .rapid-breadcrumb__item .rapid-breadcrumb__link {
    color: var(--neutral-link-color);
  }
  button.icon-btn.icon-btn--primary-outline {
    border: 1px solid var(--neutral-link-color);
    color: var(--neutral-link-color);
  }
  .rapid-button.rapid-button--primary-outline {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }
  .improved-form-wrapper .content-wrapper .steps-content h2 {
    color: var(--neutral-link-color);
  }
  button.icon-btn.icon-btn--primary-outline:focus:not(:disabled),
  button.icon-btn.icon-btn--primary-outline:hover:not(:disabled) {
    background-color: var(--neutral-200);
    border: 1px solid var(--neutral-link-color);
    color: var(--neutral-link-color);
  }
  .sidebar-multilevel__container .sidebar-multilevel .sidebar__nav .nav-item-group:hover,
  .sidebar-multilevel__container .sidebar-multilevel .sidebar__nav .nav-item:hover {
    border: none;
  }
  .sidebar-multilevel__container
    .sidebar-multilevel
    .sidebar__nav
    .nav-item-group:hover
    .nav-item-group__icon.nav-item-group__icon--section,
  .sidebar-multilevel__container .sidebar-multilevel .sidebar__nav .nav-item-group:hover .nav-item__icon,
  .sidebar-multilevel__container
    .sidebar-multilevel
    .sidebar__nav
    .nav-item:hover
    .nav-item-group__icon.nav-item-group__icon--section,
  .sidebar-multilevel__container .sidebar-multilevel .sidebar__nav .nav-item:hover .nav-item__icon {
    background-color: unset;
  }
  .sidebar-multilevel__container
    .sidebar-multilevel
    .sidebar__nav
    .nav-item
    .nav-item-group__icon.nav-item-group__icon--section
    svg,
  .sidebar-multilevel__container .sidebar-multilevel .sidebar__nav .nav-item .nav-item__icon svg,
  .sidebar-multilevel__container
    .sidebar-multilevel
    .sidebar__nav
    .nav-item-group
    .nav-item-group__icon.nav-item-group__icon--section
    svg,
  .sidebar-multilevel__container .sidebar-multilevel .sidebar__nav .nav-item-group .nav-item__icon svg {
    color: var(--neutral-link-color);
  }
  .sidebar-multilevel__container
    .sidebar-multilevel
    .sidebar__nav
    .nav-item
    .nav-item-group__icon.nav-item-group__icon--section
    svg,
  .sidebar-multilevel__container .sidebar-multilevel .sidebar__nav .nav-item .nav-item__icon svg,
  .sidebar-multilevel__container
    .sidebar-multilevel
    .sidebar__nav
    .nav-item-group
    .nav-item-group__icon.nav-item-group__icon--section
    svg,
  .sidebar-multilevel__container .sidebar-multilevel .sidebar__nav .nav-item-group .nav-item__icon svg {
    stroke: var(--neutral-link-color);
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: var(--primary-color);
    border-color: var(--primary-color);
  }
  .ant-radio-button-wrapper-checked:not(
      [class*=' ant-radio-button-wrapper-disabled']
    ).ant-radio-button-wrapper:first-child {
    border-right-color: var(--primary-color);
  }
  .ant-divider-inner-text {
    svg {
      path {
        fill: var(--primary-color);
      }
    }
  }
  .rapid-toggle-group .rapid-toggle-group__option-content[data-state='checked'] {
    background-color: var(--primary-color);
    color: var(--selected-text-color);
  }
  .rapid-avatar {
    background-color: var(--primary-color);
  }
  .rapid-header__user-popover-button:hover .rapid-avatar-wrapper .rapid-avatar {
    background-color: var(--primary-color) !important;
    opacity: 0.7;
  }
  .rapid-button.rapid-button--primary-outline:focus:not(:disabled),
  .rapid-button.rapid-button--primary-outline:hover:not(:disabled) {
    background-color: var(--primary-color-hover);
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }
  .ant-tabs .ant-tabs-tab {
    color: var(--primary-color);  
    .ant-tabs-tab-btn {
      opacity: 0.65;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    border-top: 5px solid var(--primary-color) !important;
      .ant-tabs-tab-btn {
        color: var(--primary-color);
    }
  }
}
