.PageViewerWithAttachments__ {
  &container {
    display: flex;
    position: relative;
    .ant-upload-drag {
      height: 200px;
      padding: 1rem;
    }
    .existing-attachments {
      h2 {
        margin-bottom: 5px;
        text-transform: uppercase;
        color: var(--neutral-link-color);
      }
      .ant-list {
        .ant-list-item {
          padding: 8px;
        }
      }
    }

    .pelase-select {
      width: 100%;
      text-align: center;
      padding: 3rem 1rem;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        opacity: 0.4;
      }
    }
  }
  .FormAttachments__container {
    flex-basis: 40%;
  }
  &page-viewer-container-container {
    flex-basis: 60%;
    padding: 0 1rem;
    .PageViewerWithAttachments__container {
      flex-direction: column;
    }
  }

}
